import { fabric } from 'fabric'

export const setActiveToolOnCanvas = (tool, canvas) => {
  if (tool && tool.type === "brush") {
    let brushObj = {};

    if (tool.id === "eraser") {
      brushObj.type = new fabric.EraserBrush(canvas);
      brushObj.width = 24;
    }

    if (tool.id === "chalk") {

      brushObj.type = new fabric.MarkerBrush(canvas);
      brushObj.width = 8;
    }

    // Set color as previous color
    if (canvas.freeDrawingBrush.color && ['chalk'].includes(tool.id)) {
      brushObj.color = canvas.freeDrawingBrush.color
    }

    return brushObj
  }
}

export const loadActiveToolSwatches = tool => {
  let swatches = [
    {
      id: 'swatch-1',
      code: '#b53334',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-2',
      code: '#fbfaf6',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-3',
      code: '#33374b',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-4',
      code: '#4665ab',
      type: 'color',
      isActive: false,
    },

    {
      id: 'swatch-5',
      code: '#5f92bb',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-6',
      code: '#80b8d4',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-7',
      code: '#b6d1da',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-8',
      code: '#574136',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-9',
      code: '#a45139',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-10',
      code: '#cd5b20',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-11',
      code: '#db8d31',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-12',
      code: '#d58f91',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-13',
      code: '#daa48f',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-14',
      code: '#364b4a',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-15',
      code: '#597c6d',
      type: 'color',
      isActive: false,
    },
  ]

  if (tool.id === 'highlighter') {
    swatches = [
      {
        id: 'h-swatch-1',
        code: 'rgba(255, 106, 9, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-2',
        code: 'rgba(0, 206, 246, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-3',
        code: 'rgba(114, 241, 109, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-4',
        code: 'rgba(254, 252, 31, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-5',
        code: 'rgba(255, 77, 157, 0.7)',
        type: 'color',
        isActive: false,
      },


      {
        id: 'h-swatch-6',
        code: 'rgba(104, 125, 206, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-7',
        code: 'rgba(245, 193, 172, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-8',
        code: 'rgba(206, 229, 138, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-9',
        code: 'rgba(147, 207, 197, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-10',
        code: 'rgba(223, 238, 155, 0.7)',
        type: 'color',
        isActive: false,
      },
    ]
  }

  return swatches;
}

export const loadToolElements = () => {
  let elements = [
    {
      id: "halloween_1",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "halloween_2",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "halloween_3",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "halloween_4",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "halloween_5",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "halloween_6",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "halloween_7",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "halloween_8",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "halloween_9",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "halloween_10",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    }
    /*{
      id: "sticker_1",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_2",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_3",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_4",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_5",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_6",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_7",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_8",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_9",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_10",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_11",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    }*/
  ]

  return elements;
}

export const deleteButton = () => {
  fabric.Object.prototype.controls.deleteControl = fabric.Textbox.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: -17,
    offsetX: 17,
    cursorStyle: 'pointer',
    mouseUpHandler: deleteObject,
    render: renderIcon,
    cornerSize: 24
  });

  fabric.Object.prototype.transparentCorners = true;
  fabric.Object.prototype.cornerColor = 'gray';
  fabric.Object.prototype.cornerSize = 8;
  fabric.Object.prototype.borderColor = 'gray';
  fabric.Object.prototype.borderDashArray = [5];
};

const deleteObject = (event, transform) => {
  var target = transform.target;
  var canvas = target.canvas;
      canvas.remove(target);
      canvas.requestRenderAll();
};

const renderIcon = (ctx, left, top, styleOverride, fabricObject) => {
  var deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23000000;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
  var img = document.createElement('img');
  img.src = deleteIcon; //require(`@/assets/img/ui/delete.svg`);
  
  var size = 20;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -size/2, -size/2, size, size);
  ctx.restore();
};